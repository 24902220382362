import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Container, Form, Button, Alert, Spinner, Card, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';

function PostUser() {
  const [userData, setUserData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    user_city: '',
    user_house_number: '',
    user_sub_city: '',
    user_woreda: '',
    id_photo: null,
    photo: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showIdPhotoWebcam, setShowIdPhotoWebcam] = useState(false);
  const [showAdditionalPhotoWebcam, setShowAdditionalPhotoWebcam] = useState(false);
  const [capturedImage, setCapturedImage] = useState({ id_photo: null, photo: null });
  const webcamRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setUserData({
        ...userData,
        [name]: files[0],
      });
      setCapturedImage((prev) => ({ ...prev, [name]: null }));
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleCapture = (type) => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage((prev) => ({ ...prev, [type]: imageSrc }));
    setUserData((prev) => ({ ...prev, [type]: imageSrc }));
    if (type === 'id_photo') {
      setShowIdPhotoWebcam(false);
    } else {
      setShowAdditionalPhotoWebcam(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();

      for (const [key, value] of Object.entries(userData)) {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (capturedImage[key]) {
          const blob = await (await fetch(capturedImage[key])).blob();
          formData.append(key, blob, `${key}.jpeg`);
        } else if (value) {
          formData.append(key, value);
        }
      }

      // Log FormData for debugging
      for (let pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      const response = await axios.post('https://emiruproduction.com/api/customers', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('API Response:', response.data); // Log the response for debugging

      setSuccess('User added successfully!');
      setUserData({
        user_name: '',
        user_email: '',
        user_phone: '',
        user_city: '',
        user_house_number: '',
        user_sub_city: '',
        user_woreda: '',
        id_photo: null,
        photo: null,
      });
      setCapturedImage({ id_photo: null, photo: null });

      // Optionally navigate to another page after successful submission
      // navigate('/somepage');
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        console.error('Error response data:', data); // Log the server response for debugging
        console.error('Error response status:', status); // Log the status code for debugging
        setError(data.message || 'Failed to add user. Please check the data and try again.');
      } else {
        console.error('Network or other error:', error);
        setError('Network error. Please check your connection.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleIdPhotoCapture = () => {
    handleCapture('id_photo');
  };

  const handleAdditionalPhotoCapture = () => {
    handleCapture('photo');
  };

  return (
    <Container className="mt-5">
      <Card className="p-4 shadow-sm">
        <Card.Title className="mb-4">Add New User</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="user_name">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="user_name"
                  value={userData.user_name}
                  onChange={handleChange}
                  placeholder="Enter user name"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="user_email">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  name="user_email"
                  value={userData.user_email}
                  onChange={handleChange}
                  placeholder="Enter user email"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="user_phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="user_phone"
                  value={userData.user_phone}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="user_city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="user_city"
                  value={userData.user_city}
                  onChange={handleChange}
                  placeholder="Enter city"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="user_house_number">
                <Form.Label>House Number</Form.Label>
                <Form.Control
                  type="text"
                  name="user_house_number"
                  value={userData.user_house_number}
                  onChange={handleChange}
                  placeholder="Enter house number"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="user_sub_city">
                <Form.Label>Sub City</Form.Label>
                <Form.Control
                  type="text"
                  name="user_sub_city"
                  value={userData.user_sub_city}
                  onChange={handleChange}
                  placeholder="Enter sub city"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={6}>
              <Form.Group controlId="user_woreda">
                <Form.Label>Woreda</Form.Label>
                <Form.Control
                  type="text"
                  name="user_woreda"
                  value={userData.user_woreda}
                  onChange={handleChange}
                  placeholder="Enter woreda"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="id_photo">
                <Form.Label>ID Photo (Optional)</Form.Label>
                <Form.Control
                  type="file"
                  name="id_photo"
                  onChange={handleChange}
                />
                <Button variant="link" onClick={() => setShowIdPhotoWebcam(true)}>
                  Capture with Webcam
                </Button>
                {capturedImage.id_photo && (
                  <img src={capturedImage.id_photo} alt="Captured ID" style={{ width: '50%', marginTop: '10px' }} />
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={6}>
              <Form.Group controlId="photo">
                <Form.Label>Additional Photo (Optional)</Form.Label>
                <Form.Control
                  type="file"
                  name="photo"
                  onChange={handleChange}
                />
                <Button variant="link" onClick={() => setShowAdditionalPhotoWebcam(true)}>
                  Capture with Webcam
                </Button>
                {capturedImage.photo && (
                  <img src={capturedImage.photo} alt="Captured Photo" style={{ width: '50%', marginTop: '10px' }} />
                )}
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-center">
            <Button 
              type="submit" 
              className="w-50" 
              variant="secondary" 
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
            </Button>
          </div>
        </Form>
      </Card>

      {/* ID Photo Webcam Modal */}
      <Modal show={showIdPhotoWebcam} onHide={() => setShowIdPhotoWebcam(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Capture ID Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              width: 420,
              height: 420,
              facingMode: 'user',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowIdPhotoWebcam(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleIdPhotoCapture}>
            Capture
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Additional Photo Webcam Modal */}
      <Modal show={showAdditionalPhotoWebcam} onHide={() => setShowAdditionalPhotoWebcam(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Capture Additional Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              width: 420,
              height: 420,
              facingMode: 'user',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAdditionalPhotoWebcam(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAdditionalPhotoCapture}>
            Capture
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default PostUser;
