import React, { useState } from 'react';
import { Navbar, Image, Button } from 'react-bootstrap';
import { useNavigate, NavLink } from 'react-router-dom';
import './header.css'; // Custom styles

const Sidebar = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token'); // Check if user is logged in
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isUsersDropdownOpen, setIsUsersDropdownOpen] = useState(false); // Separate state for Users dropdown
  const [isItemsDropdownOpen, setIsItemsDropdownOpen] = useState(false); // Separate state for Items dropdown
  const [isRentDropdownOpen, setIsRentDropdownOpen] = useState(false); // Separate state for Rent dropdown

  const handleLogout = () => {
    // Clear authentication data from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    // Redirect to login page
    navigate('/login');
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleUsersDropdown = () => {
    setIsUsersDropdownOpen(!isUsersDropdownOpen);
    // Close other dropdowns if open
    setIsItemsDropdownOpen(false);
    setIsRentDropdownOpen(false);
  };

  const toggleItemsDropdown = () => {
    setIsItemsDropdownOpen(!isItemsDropdownOpen);
    // Close other dropdowns if open
    setIsUsersDropdownOpen(false);
    setIsRentDropdownOpen(false);
  };

  const toggleRentDropdown = () => {
    setIsRentDropdownOpen(!isRentDropdownOpen);
    // Close other dropdowns if open
    setIsUsersDropdownOpen(false);
    setIsItemsDropdownOpen(false);
  };

  return (
    isAuthenticated && (
      <div
        className={`d-flex flex-column sidebar ${isCollapsed ? 'collapsed' : ''}`}
        style={{
          minHeight: '100vh', // Ensures it occupies full height but can expand if needed
          width: isCollapsed ? '80px' : '250px',
          backgroundColor: '#343a40',
          color: '#fff',
          transition: 'width 0.3s',
          overflow: 'hidden', // Ensures content is hidden when collapsed
        }}
      >
        <Navbar variant="dark" expand="lg" className="flex-column h-100">
          <div className="sidebar-header">
            {!isCollapsed && (
              <Image
                src="/images/logo.png"
                fluid
                className="sidebar-logo"
                style={{ maxWidth: '150px', height: 'auto', paddingLeft: '10px', marginBottom: '20px' }}
              />
            )}
             <Button
              variant="outline-light"
              size="sm"
              onClick={toggleSidebar}
              className="sidebar-toggle"
              style={{
                position: 'relative',
                display: 'block',
                width: '40px',
                height: '40px',
                backgroundColor: 'white', // Debugging
                color: 'black',
                zIndex: '1000',
              }}
            >
              <i className={`fas ${isCollapsed ? 'fa-angle-right' : 'fa-angle-left'}`}></i>
            </Button>
          </div>
          <div className="sidenav">
            <NavLink exact="true" to="/" className="nav-link">
              <i className="fas fa-columns icon"></i>
              {!isCollapsed && 'Dashboard'}
            </NavLink>
            
            <span
              className={`dropdown-text ${isUsersDropdownOpen ? 'active' : ''}`}
              onClick={toggleUsersDropdown}
            >
              <i className="fas fa-users icon"></i>
              {!isCollapsed && 'clients '}
               
              <i className="fas fa-caret-down icon" style={{marginLeft:'10px'}}></i>
              
            </span>
            <div className="dropdown-container" style={{ display: isUsersDropdownOpen ? 'block' : 'none' }}>
              <NavLink exact="true" to="/postUser" className="nav-link">
                <i className="fa fa-user-plus"></i>
                {!isCollapsed && 'Post client '}
              </NavLink>
              <NavLink exact="true" to="/listUser" className="nav-link">
                <i className="fa fa-bars"></i>
                {!isCollapsed && 'List client '}
              </NavLink>
            </div>
            
            <span
              className={`dropdown-text ${isItemsDropdownOpen ? 'active' : ''}`}
              onClick={toggleItemsDropdown}
            >
              <i className="fa fa-camera-retro"></i>
              {!isCollapsed && 'Items '}
               
              <i className="fas fa-caret-down icon" style={{marginLeft:'10px'}}></i>
              
            </span>
            <div className="dropdown-container" style={{ display: isItemsDropdownOpen ? 'block' : 'none' }}>
              <NavLink exact="true" to="/postItems" className="nav-link">
                <i className="fa fa-pencil-square"></i>
                {!isCollapsed && 'Post Items'}
              </NavLink>
              <NavLink exact="true" to="/listItems" className="nav-link">
                <i className="fa fa-bars"></i>
                {!isCollapsed && 'List Items'}
              </NavLink>
            </div>

            <span
              className={`dropdown-text ${isRentDropdownOpen ? 'active' : ''}`}
              onClick={toggleRentDropdown}
            >
              <i className="fa fa-calendar"></i>
              {!isCollapsed && 'Rent '}
               
              <i className="fas fa-caret-down icon" style={{marginLeft:'10px'}}></i>
              
            </span>
            <div className="dropdown-container" style={{ display: isRentDropdownOpen ? 'block' : 'none' }}>
              <NavLink exact="true" to="/postRent" className="nav-link">
                <i className="fa fa-pencil-square"></i>
                {!isCollapsed && 'Post Rents'}
              </NavLink>
             
              <NavLink exact="true" to="/listRentcat" className="nav-link">
                <i className="fa fa-bars"></i>
                {!isCollapsed && 'List Rents '}
              </NavLink>
            </div>
            <NavLink exact="true" to="/stock" className="nav-link">
            <i class="fas fa-chart-line" aria-hidden="true"></i>

              {!isCollapsed && 'Stock'}
            </NavLink>            
            <NavLink exact="true" to="/analytic" className="nav-link">
            <i class="fas fa-chart-pie" aria-hidden="true"></i>

              {!isCollapsed && 'Analytic'}
            </NavLink>
          </div>

          <div className="mt-auto text-center p-3" style={{ width: '100%' }}>
          <div className="container text-center">
  <span style={{ color: 'white' }}>
    Developed by 
    <a 
      href="https://www.linkedin.com/in/tewodros-bedilu/" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ 
        color: 'white', 
        textDecoration: 'none', 
        marginLeft: '4px', // Adjust spacing between text and link
        marginRight: '4px' // Adjust spacing if needed
      }} 
      onMouseOver={(e) => e.target.style.textDecoration = 'underline'} 
      onMouseOut={(e) => e.target.style.textDecoration = 'none'}
    >
      Tewodros Bedilu
    </a> 
    | Phone: +251-910719905
  </span>
</div>

            <Button
              onClick={handleLogout}
              className="btn btn-secondary mt-3"
            >
              <i className="fas fa-sign-out-alt"></i>
              {!isCollapsed && 'Logout'}
            </Button>
          </div>
        </Navbar>
      </div>
    )
  );
};

export default Sidebar;
