import React, { useState, useEffect } from "react";
import { Bar, Pie } from "react-chartjs-2";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
import "./Analytic.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const PaymentSummary = () => {
  const isAuthenticated = !!localStorage.getItem("token");

  // Define states
  const [paymentData, setPaymentData] = useState({
    labels: [],
    datasets: [
      {
        label: "Paid Payments",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Unpaid Payments",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      }
    ],
  });

  const [itemPriceData, setItemPriceData] = useState({
    labels: [],
    datasets: [
      {
        label: "Item Prices",
        data: [],
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [paymentPieData, setPaymentPieData] = useState({
    labels: [],
    datasets: [
      {
        label: "Payment Status",
        data: [],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
      },
    ],
  });

  const [totalPaid, setTotalPaid] = useState(0);
  const [totalUnpaid, setTotalUnpaid] = useState(0);
  const [totalPostPaid, setTotalPostPaid] = useState(0);
  const [totalPenalty, setTotalPenalty] = useState(0);
  const [postPaidByDateData, setPostPaidByDateData] = useState({});
const [postPaidByCustomerData, setPostPaidByCustomerData] = useState({});


  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  });

  // Handle date range change
  const handleDateRangeChange = (range) => {
    const today = new Date();
    let newStartDate;
    let newEndDate;

    switch (range) {
      case '7days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1);
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 6);
        break;
      case '15days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1);
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 14);
        break;
      case '30days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1);
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 29);
        break;
      case '90days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1);
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 89);
        break;
      case '365days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1);
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 364);
        break;
      case 'custom':
        newEndDate = endDate;
        newStartDate = startDate;
        break;
      default:
        newEndDate = today;
        newStartDate = today;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const fetchPaymentData = async () => {
    try {
      const token = localStorage.getItem("token");
  
      // Fetch payment data
      const paymentResponse = await fetch("https://emiruproduction.com/api/rental-categories/rental-categories/without", {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!paymentResponse.ok) {
        throw new Error("Failed to fetch payments");
      }
  
      const { data } = await paymentResponse.json();
  
      // Adjust the endDate to include the entire day
      let adjustedEndDate = new Date(endDate);
      adjustedEndDate.setHours(23, 59, 59, 999);
  
      // Filter payments within the selected date range
      const filteredData = data.filter(payment => {
        const createdAt = new Date(payment.createdAt);
        return createdAt >= startDate && createdAt <= adjustedEndDate;
      });
  
      // Filter post-paid and penalty amounts by their own created dates
      const postPaidFilteredData = data.filter(payment => {
        const postPaidCreatedAt = new Date(payment.post_paid_created_at);
        return postPaidCreatedAt >= startDate && postPaidCreatedAt <= adjustedEndDate;
      });
  
      const penaltyFilteredData = data.filter(payment => {
        const penaltyCreatedAt = new Date(payment.penalty_created_at);
        return penaltyCreatedAt >= startDate && penaltyCreatedAt <= adjustedEndDate;
      });
  
      // Group payments and item prices by date
      const paymentsByDate = {};
      const itemPricesByName = {};
      let totalPaidAmount = 0;
      let totalUnpaidAmount = 0;
      let totalPostPaidAmount = 0;
      let totalPenaltyAmount = 0;
  
      filteredData.forEach(payment => {
        const date = new Date(payment.createdAt).toLocaleDateString();
        if (!paymentsByDate[date]) {
          paymentsByDate[date] = { paid: 0, unpaid: 0 };
        }
        paymentsByDate[date].paid += payment.paid;
        paymentsByDate[date].unpaid += payment.not_paid;
        totalPaidAmount += payment.paid;
        totalUnpaidAmount += payment.not_paid;
  
        // Aggregate item prices by item name
        payment.rentals.forEach(rental => {
          const itemName = rental.item.item_name;
          if (!itemPricesByName[itemName]) {
            itemPricesByName[itemName] = 0;
          }
          itemPricesByName[itemName] += rental.item_price;
        });
      });
  
      // Calculate totals for post-paid and penalty amounts
      postPaidFilteredData.forEach(payment => {
        totalPostPaidAmount += payment.post_paid_amount;
      });
  
      penaltyFilteredData.forEach(payment => {
        totalPenaltyAmount += payment.penalty_amount;
      });
  
      // Prepare data for the bar charts
      const dates = Object.keys(paymentsByDate);
      const paidAmounts = dates.map(date => paymentsByDate[date].paid);
      const unpaidAmounts = dates.map(date => paymentsByDate[date].unpaid);
  
      setPaymentData({
        labels: dates,
        datasets: [
          {
            label: "Paid Payments",
            data: paidAmounts,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
          {
            label: "Unpaid Payments",
            data: unpaidAmounts,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          }
        ],
      });
  
      setItemPriceData({
        labels: Object.keys(itemPricesByName),
        datasets: [
          {
            label: "Item Prices",
            data: Object.values(itemPricesByName),
            backgroundColor: "rgba(153, 102, 255, 0.2)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          }
        ],
      });
  
      setPaymentPieData({
        labels: ["Paid", "Unpaid"],
        datasets: [
          {
            label: "Payment Status",
            data: [totalPaidAmount, totalUnpaidAmount],
            backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
          },
        ],
      });
  
      setPostPaidByDateData({
        labels: Object.keys(postPaidFilteredData.reduce((acc, curr) => {
          const date = new Date(curr.post_paid_created_at).toLocaleDateString();
          acc[date] = (acc[date] || 0) + curr.post_paid_amount;
          return acc;
        }, {})),
        datasets: [
          {
            label: "Post Paid Amount by Date",
            data: Object.values(postPaidFilteredData.reduce((acc, curr) => {
              const date = new Date(curr.post_paid_created_at).toLocaleDateString();
              acc[date] = (acc[date] || 0) + curr.post_paid_amount;
              return acc;
            }, {})),
            backgroundColor: "rgba(153, 102, 255, 0.2)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          }
        ],
      });
  
      setPostPaidByCustomerData({
        labels: Object.keys(postPaidFilteredData.reduce((acc, curr) => {
          const customerName = curr.customer_name;
          acc[customerName] = (acc[customerName] || 0) + curr.post_paid_amount;
          return acc;
        }, {})),
        datasets: [
          {
            label: "Post Paid Amount by Customer",
            data: Object.values(postPaidFilteredData.reduce((acc, curr) => {
              const customerName = curr.customer_name;
              acc[customerName] = (acc[customerName] || 0) + curr.post_paid_amount;
              return acc;
            }, {})),
            backgroundColor: "rgba(255, 159, 64, 0.2)",
            borderColor: "rgba(255, 159, 64, 1)",
            borderWidth: 1,
          }
        ],
      });
  
      setTotalPaid(totalPaidAmount);
      setTotalUnpaid(totalUnpaidAmount);
      setTotalPostPaid(totalPostPaidAmount);
      setTotalPenalty(totalPenaltyAmount);
  
    } catch (error) {
      console.error("Error fetching payment data:", error);
    }
  };
  

  useEffect(() => {
    fetchPaymentData();
  }, [startDate, endDate]);

  return (
    <div className="container">
      <div className="row mb-4">
      
        <div className="col-md-6 mb-4">
        <h1 className="mb-4">Payment Summary</h1>
          <div className="welcome-message">Select date range</div>
          <div className="d-flex">
          <div className="d-flex">
        
              <div className="me-2">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  className="custom-date-picker"
                />
              </div>
              <div>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  className="custom-date-picker"
                />
              </div>
              <div className="date-range-selector">
              <select 
                onChange={(e) => handleDateRangeChange(e.target.value)} 
                defaultValue="7days" 
                className="form-select"
              >
                <option value="7days">Last 7 Days</option>
                <option value="15days">Last 15 Days</option>
                <option value="30days">Last 30 Days</option>
                <option value="90days">Last 90 Days</option>
                <option value="365days">Last 365 Days</option>
              </select>
            </div>
            </div>
        </div>
      
          </div>
      </div>
      <Row className="mb-4">
          <Col md={4} sm={12}>
            <Card className="analytics-card">
              <Card.Body>
              <i 
          className="fas fa-check-circle fa-3x" 
          style={{ color: 'green' }}
          title="Paid"
        ></i>     <Card.Title>Total Paid</Card.Title>
                <Card.Text>{totalPaid.toFixed(2)}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={12}>
            <Card className="analytics-card">
              <Card.Body>
              <i 
          className="fas fa-times-circle fa-3x" 
          style={{ color: 'red' }}
          title="Unpaid"
        ></i>
                <Card.Title>Total Unpaid</Card.Title>
                <Card.Text>{totalUnpaid.toFixed(2)}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={12}>
            
          </Col>
          <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <h5 className="card-title">Total Post-Paid</h5>
              <p className="card-text">${totalPostPaid.toFixed(2)}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <h5 className="card-title">Total Penalty</h5>
              <p className="card-text">${totalPenalty.toFixed(2)}</p>
            </Card.Body>
          </Card>
        </Col>
        </Row>
      <div className="row">
        <div className="col-md-6 mb-4">
          <h3>Payments by Date</h3>
          <Bar
            data={paymentData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      const label = tooltipItem.dataset.label || "";
                      return `${label}: ${tooltipItem.raw.toFixed(2)}`;
                    },
                  },
                },
              },
            }}
          />
        </div>
        <div className="col-md-6 mb-4">
          <h3>Item Prices</h3>
          <Bar
            data={itemPriceData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      const label = tooltipItem.dataset.label || "";
                      return `${label}: ${tooltipItem.raw.toFixed(2)}`;
                    },
                  },
                },
              },
            }}
          />
        </div>
       
      </div>
    </div>
  );
};

export default PaymentSummary;
