// src/components/LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert, Spinner, InputGroup } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await fetch('https://emiruproduction.com/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        if (token) {
          // Store tokens, email, and role in local storage
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', data.refreshToken);
          localStorage.setItem('email', email);
          localStorage.setItem('role', data.role);

          // Redirect based on user role
          if (data.role === 'ADMIN') {
            navigate('/');
          } else {
            navigate('/');
          }
        } else {
          setError('Login failed. Please try again.');
        }
      } else {
        const errorData = await response.json();
        // Improved error handling
        if (response.status === 401) {
          setError(errorData.message || 'Invalid credentials. Please check your email and password.');
        } else if (response.status === 404) {
          setError(errorData.message || 'Email or user not found.');
        } else {
          setError(errorData.message || 'An error occurred. Please try again later.');
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Network error. Please check your connection.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="full-height-container">
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Row className="w-100">
          <Col md={6} lg={4} className="mx-auto">
            <div className="bg-light p-4 rounded shadow">
              <div className="image-container mb-4">
                <Image
                  src="/images/logo.png"
                  fluid
                  className="logo-image"
                  alt="Logo"
                />
              </div>
              <h2 className="text-center mb-4">Login</h2>
              {error && <Alert variant="danger" className="new-alert">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Remember Me"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                </Form.Group>

                <Button variant="secondary" type="submit" className="w-100" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Login'}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        
      </Container>
      <div className="container text-center" >
  <span style={{ color: 'black' }}>
    Developed by 
    <a 
      href="https://www.linkedin.com/in/tewodros-bedilu/" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ 
        color: 'Black', 
        textDecoration: 'none', 
        marginLeft: '4px', // Adjust spacing between text and link
        marginRight: '4px', // Adjust spacing if needed
        marginBottom: '44px' // Adjust spacing if needed
      }} 
      onMouseOver={(e) => e.target.style.textDecoration = 'underline'} 
      onMouseOut={(e) => e.target.style.textDecoration = 'none'}
    >
      Tewodros Bedilu
    </a> 
    | Phone: +251-910719905
  </span>
</div> 
    </div>
  );
};

export default LoginPage;
