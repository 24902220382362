import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Table, Alert, Spinner, Button, Dropdown, Pagination, Form } from 'react-bootstrap';
import './RentalCategoryDetails.css'; // Import custom styles

const RentalCategoryDetails = () => {
  const [rentalCategories, setRentalCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // Adjust to match API response
  const [updateAmount, setUpdateAmount] = useState({}); // State for input values
  const [returnQuantities, setReturnQuantities] = useState({}); // State for return quantities
  const [updating, setUpdating] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  const handleError = (error) => {
    console.error('Error:', error);
    alert('An error occurred. Please try again.');
  };
  
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
    
      if (!token) {
        navigate('/login');
        return;
      }
    
      try {
        const response = await axios.get('https://emiruproduction.com/api/rental-categories/rental-categories', {
          params: { page: currentPage, limit: itemsPerPage, search: searchQuery }, // Include search query
          headers: { Authorization: `Bearer ${token}` },
        });
    
        console.log('API Response:', response.data); // Log the response data
        setRentalCategories(response.data.data); // Update based on response structure
        setTotalPages(response.data.totalPages); // Set total pages from response
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err); // Log detailed error
        if (err.response && err.response.status === 401) {
          navigate('/login');
        } else {
          setError('Failed to fetch rental categories. Please try again.');
        }
        setLoading(false);
      }
    };
    
    fetchData();
  }, [navigate, currentPage, itemsPerPage, searchQuery]); // Add searchQuery to dependency array

  const updateCategoryStatus = async (categoryId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');
  
      // Fetch the category details
      const categoryResponse = await axios.get(
        `https://emiruproduction.com/api/rental-categories/rental-categories/${categoryId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const category = categoryResponse.data;
  
      // Determine the new status of the category
      const allReturned = category.rentals.every(rental => rental.status === 'Return');
      const paidEqualsTotal = parseFloat(category.paid)+ parseFloat(category.post_paid_amount) >= parseFloat(category.total_price);
      const anyNotReturned = category.rentals.some(rental => rental.status === 'NotReturn');
  
      let newStatus = 'pending'; // Default to 'pending'
  
      if (allReturned && paidEqualsTotal) {
        newStatus = 'completed';
      } else if (anyNotReturned) {
        newStatus = 'pending';
      }
  
      // Fetch the current status of the category to check if it needs to be updated
      const currentCategoryResponse = await axios.get(
        `https://emiruproduction.com/api/rental-categories/rental-categories/${categoryId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const currentCategory = currentCategoryResponse.data;
  
      // Update status if it has changed
      if (currentCategory.status !== newStatus) {
        await axios.put(
          `https://emiruproduction.com/api/rental-categories/rental-categories/${categoryId}`,
          { status: newStatus.charAt(0).toUpperCase() + newStatus.slice(1) }, // Capitalize status
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
  
      // Refresh the category list
      const fetchResponse = await axios.get(
        'https://emiruproduction.com/api/rental-categories/rental-categories',
        {
          params: { page: currentPage, limit: itemsPerPage },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      setRentalCategories(fetchResponse.data.data); // Update based on response structure
    } catch (error) {
      console.error('Error updating category status:', error); // Log detailed error
      if (error.response) {
        setError(error.response.data.error || 'Failed to update category status. Please try again.');
      } else {
        setError('Failed to update category status. Please try again.');
      }
    }
  };
  

  const handleStatusUpdate = async (rentalId, newStatus, categoryId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      await axios.put(
        `https://emiruproduction.com/api/rentals/${rentalId}`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      updateCategoryStatus(categoryId);
      // Recalculate penalties
      // Refresh rental categories after status update
      const response = await axios.get('https://emiruproduction.com/api/rental-categories/rental-categories', {
        params: { page: currentPage, limit: itemsPerPage },
        headers: { Authorization: `Bearer ${token}` },
      });

      setRentalCategories(response.data.data); // Update based on response structure

      
    } catch (error) {
      console.error('Error updating status:', error); // Log detailed error
      if (error.response) {
        setError(error.response.data.error || 'Failed to update status. Please try again.');
      } else {
        setError('Failed to update status. Please try again.');
      }
    }
  };
  const handleUpdatePrice = async (categoryId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');
  
      const category = rentalCategories.find(cat => cat.category_id === categoryId);
      const amount = parseFloat(updateAmount[categoryId] || 0);
  
      // Ensure category exists
      if (!category) {
        setError('Category not found.');
        return;
      }
  
      let updatedCategory;
  
      if (amount > 0) {
        // Positive amount: Decrease not_paid and increase post_paid_amount
        if (amount > category.not_paid) {
          setError('Amount exceeds not paid value.');
          return;
        }
        updatedCategory = {
          ...category,
          post_paid_amount: (parseFloat(category.post_paid_amount) + amount).toFixed(2),
          not_paid: (parseFloat(category.not_paid) - amount).toFixed(2),
        };
      } else if (amount < 0) {
        // Negative amount: Increase not_paid and decrease post_paid_amount
        const absAmount = Math.abs(amount);
        if (absAmount > parseFloat(category.post_paid_amount)) {
          setError('Amount exceeds paid value.');
          return;
        }
        updatedCategory = {
          ...category,
          post_paid_amount: (parseFloat(category.post_paid_amount) - absAmount).toFixed(2),
          not_paid: (parseFloat(category.not_paid) + absAmount).toFixed(2),
        };
      } else {
        setError('Amount must be non-zero.');
        return;
      }
  
      await axios.put(
        `https://emiruproduction.com/api/rental-categories/rental-categories/${categoryId}`,
        updatedCategory,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Check and update category status after payment update
      updateCategoryStatus(categoryId);
  
      // Refresh rental categories after payment and status update
      const fetchResponse = await axios.get('https://emiruproduction.com/api/rental-categories/rental-categories', {
        params: { page: currentPage, limit: itemsPerPage },
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setRentalCategories(fetchResponse.data.data); // Update based on response structure
      setUpdateAmount({ ...updateAmount, [categoryId]: '' }); // Reset the input field
    } catch (error) {
      console.error('Error updating price:', error); // Log detailed error
      if (error.response) {
        setError(error.response.data.error || 'Failed to update price. Please try again.');
      } else {
        setError('Failed to update price. Please try again.');
      }
    }
  };
  const handleUpdatePenalty = async (categoryId) => {
    setUpdating(true);
  
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');
  
      const category = rentalCategories.find((cat) => cat.category_id === categoryId);
      if (!category) throw new Error('Category not found');
  
      const totalPenalty = calculateTotalPenalty(category.rentals);
  
      await axios.put(
        `https://emiruproduction.com/api/rental-categories/rental-categories/${categoryId}`,
        { penalty_amount: totalPenalty },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Refresh rental categories
      const fetchResponse = await axios.get('https://emiruproduction.com/api/rental-categories/rental-categories', {
        params: { page: currentPage, limit: itemsPerPage },
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setRentalCategories(fetchResponse.data.data);
    } catch (error) {
      handleError(error);
    } finally {
      setUpdating(false);
    }
  };
  
  const handleUpdateReturnQuantity = async (rentalId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const rental = rentalCategories.flatMap(cat => cat.rentals).find(r => r.rental_id === rentalId);
      const returnQuantity = parseInt(returnQuantities[rentalId] || 0);

      await axios.put(
        `https://emiruproduction.com/api/rentals/${rentalId}`,
        { return_quantity: returnQuantity },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Refresh rental categories after return quantity update
      const response = await axios.get('https://emiruproduction.com/api/rental-categories/rental-categories', {
        params: { page: currentPage, limit: itemsPerPage },
        headers: { Authorization: `Bearer ${token}` },
      });

      setRentalCategories(response.data.data); // Update based on response structure
    } catch (error) {
      console.error('Error updating return quantity:', error); // Log detailed error
      if (error.response) {
        setError(error.response.data.error || 'Failed to update return quantity. Please try again.');
      } else {
        setError('Failed to update return quantity. Please try again.');
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const calculatePenalty = (status, endDate, quantity, itemPrice) => {
    if (status !== 'NotReturn') {
      return '0.00';
    }
  
    const currentDate = new Date();
    const endDateObj = new Date(endDate);
    
    if (currentDate > endDateObj) {
      const daysOverdue = Math.ceil((currentDate - endDateObj) / (1000 * 60 * 60 * 24));
      return (quantity * itemPrice * daysOverdue).toFixed(2);
    }
    
    return '0.00';
  };
  
  const calculateTotalPenalty = (rentals) => {
    return rentals.reduce((total, rental) => {
      const penalty = calculatePenalty(rental.status, rental.end_date, rental.quantity, rental.item_price);
      return total + parseFloat(penalty);
    }, 0).toFixed(2);
  };
  
  

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  if (error)
    return (
      <Alert variant="danger">
        {error}{' '}
        <Button variant="link" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </Alert>
    );

  if (rentalCategories.length === 0)
    return <Alert variant="info">No rental category data found. <Button variant="link" onClick={() => window.location.reload()}>
  Retry
</Button></Alert>;

  return (
    <Container className="mt-5">
    <Form.Control
  type="text"
  placeholder="Search by customer name or item name"
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
/>

      <Container className="mt-5 d-flex justify-content-between align-items-center">
  <h1 className="mb-4">Rental List</h1>
  
  <Form.Select
    value={itemsPerPage}
    onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
    style={{ width: 'auto', maxWidth: '200px' }}
    className="form-select"
  >
    {[1,2, 5, 10, 20].map((size) => (
      <option key={size} value={size}>
        {size} per page
      </option>
    ))}
  </Form.Select>
</Container>

      {rentalCategories.map((category) => {
        const firstCustomerName =
          category.rentals.length > 0 && category.rentals[0].customer
            ? category.rentals[0].customer.user_name
            : 'N/A';

        const statusVariant = category.status === 'Completed'
          ? 'completed'
          : category.status === 'Pending'
          ? 'pending'
          : 'pending';
          const totalPenalty = calculateTotalPenalty(category.rentals);

        return (
          <Card className="mb-3" key={category.category_id}>
            <Card.Body>
            <Card.Title style={{ color: '#7c997d' }}>
  {firstCustomerName}
</Card.Title>


<div className="container mt-4">
  <div className="row">
    <div className="col-md-8 offset-md-2">
      <div className="card border-primary mb-3">
      
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 mb-3">
              <p className="card-text">
                <strong>Total Price:</strong> {category.total_price}
              </p>
              <p className="card-text">
                <strong>Paid:</strong> {category.paid}
              </p>
              <p className="card-text">
                <strong>Post Paid Amount:</strong> {category.post_paid_amount}
              </p>
            </div>
            <div className="col-md-6 mb-3">
             
              <p className="card-text text-danger">
                <strong>Not Paid:</strong> {category.not_paid}
              </p>
              <p className="card-text text-danger">
                <strong>Paid Penalty:</strong> {category.penalty_amount} birr
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

     
              <Alert variant={statusVariant} className="mb-3 custom-alert">
                Status: {category.status}
              </Alert>

              <Table striped bordered hover>
  <thead>
    <tr>
      <th>Item</th>
      <th>User</th>
      <th>Quantity</th>
      <th>Return Quantity</th>

      <th>Item price</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Status</th>
      <th>Penalty</th> {/* New column for penalty */}
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {category.rentals.map((rental) => (
      
      <tr key={rental.rental_id}>
        <td>{rental.item ? rental.item.item_name || `Item ${rental.item.item_id}` : 'N/A'}</td>
        <td>{rental.customer ? rental.customer.user_name : 'N/A'}</td>
        <td>{rental.quantity}</td>
        <td>
        
                      <Form.Control
                        type="number"
                        value={returnQuantities[rental.rental_id] ||  rental.return_quantity}
                        onChange={(e) => setReturnQuantities({
                          ...returnQuantities,
                          [rental.rental_id]: e.target.value,
                        })}
                      />
                   
                        <Button
                        variant="xxx"
                        onClick={() => handleUpdateReturnQuantity(rental.rental_id)}
                      >
                       Set Return Quantity
                      </Button>
                    </td>
                    
        <td>{rental.item_price}</td>
        <td>{new Date(rental.start_date).toLocaleDateString()}</td>
        <td>{new Date(rental.end_date).toLocaleDateString()}</td>
        <td>{rental.status}</td>
        <td>{calculatePenalty(rental.status, rental.end_date, rental.quantity, rental.item_price)}</td> {/* Display penalty */}
        <td>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Update Status
            </Dropdown.Toggle>
            <Dropdown.Menu>
            <Dropdown.Item
        onClick={() => handleStatusUpdate(rental.rental_id, 'Return', category.category_id)}
        disabled={rental.return_quantity!== rental.quantity}
      >

                Returned
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatusUpdate(rental.rental_id, 'NotReturn', category.category_id)}>
                Not Returned
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))}
  </tbody>
</Table>



<Form className="mt-3">
  <div className="row align-items-center">
    <div className="col-md-6 mb-3">
      <Form.Group controlId={`formAmount${category.category_id}`} className="d-flex">
        <Form.Label className="mr-2 my-auto">Amount to pay Post paid:</Form.Label>
        <Form.Control
          type="number"
          value={updateAmount[category.category_id] || ''}
          onChange={(e) => setUpdateAmount({ ...updateAmount, [category.category_id]: e.target.value })}
          placeholder="Enter amount"
          className="flex-grow-1"
        />
      </Form.Group>
    </div>
    <div className="col-md-6 mb-3 d-flex align-items-center">
      <Button
        variant="secondary"
        onClick={() => handleUpdatePrice(category.category_id)}
        className="mr-3"
        style={{ flex: '0 1 auto' }}
      >
        Pay Post Paid
      </Button>
      
   
  
    </div>
    <div className="col-md-6 mb-3">
   
    <p className="mb-2">Total Penalty: {totalPenalty} birr</p>
        <Button
          variant="warning"
          onClick={() => handleUpdatePenalty(category.category_id)}
          disabled={updating}
          style={{ flex: '0 1 auto' }}
        >
          {updating ? 'Updating...' : 'Pay Penalty'}
        </Button>
  
    </div>
  </div>
</Form>


              {error && (
    <div className="alert alert-danger mt-2">
      {error}
    </div>
  )}
            </Card.Body>
          </Card>
        );
      })}

      <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {[...Array(totalPages).keys()].map((number) => (
          <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => handlePageChange(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} />
      </Pagination>
    </Container>
  );
};

export default RentalCategoryDetails;